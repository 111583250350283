import React, { useState } from 'react';
import axios from './axiosConfig'; 

const BookDemoModal = () => {
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  // State for form inputs
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    time: ''
  });

  // State for handling submission status
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handle form submission with axios
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmissionError(null);

    // Combine selected date and time
    const selectedDate = formData.date;
    const selectedTime = formData.time;

      // Log the data to ensure it's correct
  console.log({
    email: formData.email,
    selected_time: `${selectedDate}T${selectedTime}:00`  // Ensure it's in a valid datetime format
  });

    // Prevent submission if the selected time is in the past
    const now = new Date();
    const selectedDateTime = new Date(`${selectedDate}T${selectedTime}`);

    if (selectedDateTime < now) {
      setSubmissionError('Please selected the upcoming hour and date.');
      setIsSubmitting(false);
      return;
    }

    try {
      // Call the demo notification API with axios
      const response = await axios.post('/api/auth/demo', {
        
        email: formData.email,
        selected_time: `${selectedDate} ${selectedTime}` // Combine date and time
        
      });

      if (response.status === 200) {
        // Handle success (e.g., close the modal or show a success message)
        alert('Demo request submitted successfully!');
        setShowModal(false);
      } else {
        throw new Error('Failed to submit demo request');
      }
    } catch (error) {
      // Handle error
      setSubmissionError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Generate available times, blocking past times for today
  const generateAvailableTimes = () => {
    const availableTimes = ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30','18:00','18:30','19:00','20:00','20:30'];
    const now = new Date();
    
    // If the selected date is today, filter out past times
    if (formData.date === today) {
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();
      return availableTimes.filter(time => {
        const [hour, minute] = time.split(':').map(Number);
        return hour > currentHour || (hour === currentHour && minute >= currentMinutes);
      });
    }
    
    return availableTimes;
  };

  return (
    <>
    <section className="py-20 text-center">
        <div className="container mx-auto px-4">
            <h3 className="text-lg text-gray-600 mb-4">Get started</h3>
            <h2 className="text-5xl font-bold mb-6">Let's make your legal<br />research</h2>
            <p className="text-xl mb-8">Book a demo to get started</p>
            {/* Button to trigger the modal */}
            <button 
                className="bg-black text-white px-6 py-3 rounded-md text-lg flex items-center justify-center mx-auto"
                onClick={() => setShowModal(true)}  // Show modal when clicked
            >
                Book a demo
        </button>
        </div>
      </section>

      {/* Modal Component */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">Register for a Demo</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                  placeholder="Enter your email"
                  required
                />
              </div>
              {/* Date Input */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                  Select a Date
                </label>
                <input
                  id="date"
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              {/* Time Input */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="time">
                  Select a Time
                </label>
                <select
                  id="time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                  required
                >
                  <option value="" disabled>Select a time</option>
                  {generateAvailableTimes().map((time, index) => (
                    <option key={index} value={time}>{time}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-between items-center">
                <button 
                  type="submit" 
                  className="bg-slate-900 text-white px-4 py-2 rounded-lg"
                  disabled={isSubmitting}  // Disable button while submitting
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
                <button 
                  type="button" 
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setShowModal(false)}  // Close modal
                >
                  Cancel
                </button>
              </div>
              {submissionError && <p className="text-red-500 mt-4">{submissionError}</p>}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BookDemoModal;
