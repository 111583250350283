import React, { useState, useEffect } from 'react';
import BookDemoModal from './BookDemoModal';  // Import the reusable component
import Footer from './Footer';

const DataPage = () => {
  // State to store the file names
  const [dataList, setDataList] = useState([]);

  // Fetch the local JSON file when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the local file_names.json from the public folder
        const response = await fetch('/file_names.json');
        const data = await response.json();
        setDataList(data); // Store the data in the state
      } catch (error) {
        console.error('Error fetching the data', error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-6">Available Data</h1>
      
      {/* Display the list of data in 3 columns */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6">
        {dataList.length > 0 ? (
          dataList.map((dataItem, index) => (
            <div key={index} className="p-4 bg-white rounded-lg shadow-md flex items-center justify-between">
              <span className="text-lg font-semibold">{dataItem.name}</span>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
      {/* Call-to-Action Section */}
    <BookDemoModal />


{/* Footer */}
<Footer />
    </div>
  );
};

export default DataPage;
