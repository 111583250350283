import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure the base URL is correct
});

// Optional: Disable caching for every request
instance.interceptors.request.use(
  (config) => {
    config.headers['Cache-Control'] = 'no-cache';  // Disable caching
    config.headers['Pragma'] = 'no-cache';  // Disable caching for older browsers
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
