import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';  // For routing to use case details
import BookDemoModal from './BookDemoModal';  // Import the reusable component
import Footer from './Footer';
import axios from './axiosConfig';  // Import axios for API calls

const UseCasePage = () => {
  // State to hold use cases
  const [useCases, setUseCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from API when component mounts
  useEffect(() => {
    const fetchUseCases = async () => {
      try {
        const response = await axios.get('/api/usecases');
        console.log('API Response:', response.data); // Log the response data
        setUseCases(response.data); // Set fetched use cases in state
        console.log(Array.isArray(response.data)); // Should return true
        setLoading(false);
      } catch (err) {
        console.error("Error fetching use cases:", err); // Log any errors
        setError('Failed to fetch use cases');
        setLoading(false);
      }
    };
    fetchUseCases();
  }, []);

  // Handle loading and error states
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-6">Use Cases</h1>
      
      {/* Display the list of use cases */}
      <div className="grid grid-cols-1 sm:grid-cols-1 gap-8">
        {Array.isArray(useCases) && useCases.length > 0 ? (
          useCases.map((useCase) => (
            <Link to={`/usecase/${useCase._id}`} key={useCase._id} className="block p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              {/* Use Case Image */}
              <img 
                src={useCase.image} 
                alt={useCase.title} 
                className="w-[600px] h-[400px] object-cover rounded-lg mb-4"
              />
              {/* Use Case Title and Description */}
              <h2 className="text-2xl font-bold mb-2">{useCase.title}</h2>
              <p className="text-gray-600">{useCase.description}</p>
            </Link>
          ))
        ) : (
          <p>No use cases available</p> // Display this if useCases is empty or not an array
        )}
      </div>

      {/* Call-to-Action Section */}
      <BookDemoModal />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UseCasePage;
