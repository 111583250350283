import React, { useState, useEffect } from 'react';
import {FileText, Compass, Infinity, Unlock, CheckCircle, Zap, Linkedin } from 'lucide-react';
import './index.css'; // Assuming your CSS file is called index.css and located in the src folder
import BookDemoModal from './BookDemoModal';  // Import the reusable component
import BookDemoLanding from './BookDemoLanding';
import Footer from './Footer';
import { Link } from 'react-router-dom';


const TypingText = () => {
  const sentences = [
    "เอกสารทางกฎหมายฉบับนี้สอดคล้องกับกฎหมายแพ่งข้อไหน?",
    "มีคดีไหนที่โจทย์ถูกไล่ออกเพราะทะเลาะวิวาทแล้วชนะในศาลมั้ย?",
    "หาคดีที่มีการฟ้องเกี่ยวกับคุณภาพและราคาของสินค้าให้หน่อย",
    "ช่วยสรุปเอกสารทั้งหมดให้หน่อย ขอคดีที่คล้ายกับเอกสารที่ส่งไปด้วย",
    "ช่วยเช็คสัญญาฉบับนี้ว่ามีข้อไหนผิดระเบียบ PDPA มั้ย?",
    "ช่วยดูรายงานที่ไปคุยกับลูกความมา หากจะฟ้องจะใช้ข้อหาไหนได้บ้าง"
];

  const [currentText, setCurrentText] = useState("");  // Track the text being typed
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);  // Which sentence is being typed
  const [charIndex, setCharIndex] = useState(0);  // Which character is being typed
  const [isTyping, setIsTyping] = useState(true);  // Control when typing happens

  // Control the typing effect
  useEffect(() => {
    let timeout;
    if (isTyping && charIndex < sentences[currentSentenceIndex].length) {
      // Set a timeout for typing each character
      timeout = setTimeout(() => {
        setCurrentText(prev => prev + sentences[currentSentenceIndex][charIndex]);  // Add next character
        setCharIndex(charIndex + 1);  // Move to next character
      }, 100);  // Typing speed (100ms per character)
    } else if (charIndex === sentences[currentSentenceIndex].length) {
      // Sentence finished typing, pause before moving to the next sentence
      setIsTyping(false);
      timeout = setTimeout(() => {
        setCharIndex(0);
        setCurrentText("");  // Reset text
        setCurrentSentenceIndex((currentSentenceIndex + 1) % sentences.length);  // Move to the next sentence
        setIsTyping(true);  // Start typing next sentence
      }, 2000);  // Pause for 2 seconds after the sentence finishes typing
    }
    return () => clearTimeout(timeout);  // Cleanup the timeout on each render
  }, [isTyping, charIndex, sentences, currentSentenceIndex]);

  return (
    <div className="bg-white bg-opacity-0 px-2 py-1 inline-block relative w-5/6 h-8 overflow-hidden">
      <p className="text-gray-700 whitespace-nowrap text-left">
        {currentText}
        <span className="blinking-cursor"> </span>  {/* Blinking cursor */}
      </p>
    </div>
  );
};


const LandingPage = () => {

  return (
    <div className="container mx-20 px-20 font-sans">
    <main>
    {/* Hero Section */}
      <section className="container mx-auto px-4 py-12 text-center">

        <p className="bg-gray-200 rounded-full w-auto px-6 py-3 inline-block mb-8">
          {/* <a href="#" className=" text-gray-600 hover:text-gray-900">Blog The ICON CASE ↗</a> */}
          <Link to="/blog/67190a995fc1a90429e239d4" className="text-gray-600 hover:text-gray-900">
            Blog The ICON CASE ↗
          </Link>
        </p>
        <h1 className="text-5xl font-bold mb-6">Every law, argument<br />and reference matters.</h1>
        <p className="text-xl mb-8">And a tools to perfect every detail.</p>
        <BookDemoLanding />
        
        <div className="relative mt-12 rounded-lg p-12 h-80 flex items-center justify-center overflow-hidden">
        {/* Background Video */}
        <video
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src="videos/waves.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Foreground Content */}
        <div className="relative bg-white bg-opacity-65 rounded-full w-4/6 px-6 py-3 inline-block my-6 z-10">
          {/* Typing Text Component */}
          <TypingText />
        </div>
          {/* <img src="/api/placeholder/800/400" alt="Decorative waves" className="mx-auto rounded-lg" /> */}
        </div>
        </section>
        {/* About KHORN Section */}
        <section className="container mx-auto px-4 py-12 mb-20">
          <h2 className="text-2xl font-semibold mb-4">KHORN</h2>
          <p className="text-3xl font-bold mb-6">
            KHORN is an AI-powered legal research workspace designed for law firms and legal professionals. streamlining repetitive tasks and providing access to public resources and private data in one centralized system.
          </p>
        </section>

        {/* Platform Features Section */}
        <section className="container mx-auto px-4 py-12">
          <h3 className="text-xl text-gray-600 mb-4">Our platform</h3>
          <h2 className="text-5xl font-bold mb-12">Your new legal research<br />workspace.</h2>
          
          <div className="flex justify-between items-start">
            <div className="w-1/2">
              <div className="flex items-center mb-6">
                <FileText className="mr-2" />
                <span className="font-semibold">Aggregate information</span>
              </div>
              <p className="text-gray-600 mb-8">
                Gain insight from hundreds of documents at a few minutes.
              </p>
              <div className="flex items-center mb-6">
                <Compass className="mr-2" />
                <span className="font-semibold">Source within reach</span>
              </div>
              <p className="text-gray-600 mb-8">
                Every analyze, review, and answer will be sbacked with vast references.
              </p>
              <div className="flex items-center mb-6">
                <Infinity className="mr-2" />
                <span className="font-semibold">Endless accessibility</span>
              </div>
              <p className="text-gray-600 mb-8">
                Access all public laws and recorded cases in Thailand.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-20 mt-20 relative overflow-hidden rounded-lg">
          {/* Background Video */}
          <video className="absolute inset-0 w-full h-full object-cover z-0" autoPlay loop muted>
            <source src="/videos/waves2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="container mx-auto px-4 relative z-10">
            <h3 className="text-xl text-gray-600 mb-4">Benefits</h3>
            <h2 className="text-5xl font-bold mb-12">Enhancing legal operations.</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-lg">
                <Unlock className="mb-4 text-gray-700" size={24} />
                <h4 className="text-xl font-semibold mb-2">Unlocked Knowledge</h4>
                <p className="text-gray-600">Surface previous work, policies, and templates from the depths of your organization.</p>
              </div>
              <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-lg">
                <CheckCircle className="mb-4 text-gray-700" size={24} />
                <h4 className="text-xl font-semibold mb-2">Improved Outcomes</h4>
                <p className="text-gray-600">Dig deeper into your research. Proof-read in a moment. Consider more factors.</p>
              </div>
              <div className="bg-white bg-opacity-80 p-6 rounded-lg shadow-lg">
                <Zap className="mb-4 text-gray-700" size={24} />
                <h4 className="text-xl font-semibold mb-2">Increased Productivity</h4>
                <p className="text-gray-600">Breeze through tedious tasks and spend your time on creating value for your clients.</p>
              </div>
            </div>
          </div>
        </section>
      </main>

    {/* Call-to-Action Section */}
    <BookDemoModal />


    {/* Footer */}
    <Footer />
    </div>
  );
};

export default LandingPage;