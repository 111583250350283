import React from 'react';
import { Link } from 'react-router-dom';

const NavigationBar = () => {
  return (
    <header className="flex items-center justify-between p-5 border mt-4 mx-20 rounded-xl">
      <Link to="/" className="text-3xl font-bold">
        K
    </Link>
      <nav className="ml-auto">
        <ul className="flex space-x-6">
          <li>
            <Link to="/usecase" className="text-gray-600 hover:text-gray-900">
              Use Cases
            </Link>
          </li>
          <li>
          <Link to="/data" className="text-gray-600 hover:text-gray-900">
              Data
            </Link>
          </li>
          {/* <li>
            <Link to="/customers" className="text-gray-600 hover:text-gray-900">
              Customers
            </Link>
          </li> */}
          <li>
            <Link to="/blog" className="text-gray-600 hover:text-gray-900">
              Blog
            </Link>
          </li>
          {/* External link to the login page */}
          <li>
            <a href="https://app.khorn-ai.com" className="bg-black text-white px-4 py-2 rounded-md">
              LOGIN
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default NavigationBar;
