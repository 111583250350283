import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  
import LandingPage from './LandingPage';  // Your existing landing page
import DataPage from './DataPage';  // New Data page
import NavigationBar from './NavigationBar';  // Navigation bar
import BlogPage from './BlogPage';  // Blog list page
import BlogDetail from './BlogDetail';  // Blog detail page
import UseCasePage from './UseCasePage';
import UseCaseDetail from './UseCaseDetail';  // Detailed view of each use case


const App = () => {
  return (
    <Router>
      <div>
        <NavigationBar /> {/* This is your navigation bar */}

        <Routes>
          {/* Route for Landing Page */}
          <Route exact path="/" element={<LandingPage />} />

          {/* Route for Usecase Page */}
          <Route exact path="/usecase" element={<UseCasePage />} />

           {/* Route for the Use Case Detail */}
        <Route exact path="/usecase/:id" element={<UseCaseDetail />} />

          {/* Route for Data Page */}
          <Route path="/data" element={<DataPage />} />

          {/* Route for Blog Page */}
          <Route exact path="/blog" element={<BlogPage />} />

          {/* Route for Blog Detail Page */}
          <Route path="/blog/:id" element={<BlogDetail />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;
