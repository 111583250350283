import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <div className="mr-2 text-3xl font-bold">K</div>
            <span className="text-xl">KHORN</span>
          </div>
          <nav>
            <ul className="flex space-x-6">
              <li><Link to="/usecase" className="text-gray-600 hover:text-gray-900">Use Cases</Link></li>
              <li><Link to="/blog" className="text-gray-600 hover:text-gray-900">Blog</Link></li>
              <li><Link to="/data" className="text-gray-600 hover:text-gray-900">Data</Link></li>
            </ul>
          </nav>
        </div>
        <div className="flex justify-between items-center text-sm text-gray-500">
          <p>&copy; 2024 Khorn AI Solution Co., Ltd. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
